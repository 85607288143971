app-img<template>
  <div class="wrapper">
    <div class="header" id="header">
      <div class="yxj-con-room">
        <div class="yxj-container header-content yxj-space">
          <div class="leftLogo">
            <div class="login-area">
              <router-link tag="div" class="app" to="/home">
                <img
                    class="app-img"
                    src="@/assets/logo.png"
                  >
                <p class="app-text">洛阳市驾驶员培训公共服务平台</p>
              </router-link>
            </div>
          </div>
          <div class="yxj-center mobile-menu-wrapper" :class="{ active: $store.getters.openMenu }"
            @click="$store.commit('CHANGE_OPENMENU', false)" id="mask">

            <div class="yxj-center mobile-flex" @click.stop>
              <div class="yxj-center mobile-close" v-if="$store.getters.mobile"
                @click.stop="$store.commit('CHANGE_OPENMENU', false)"></div>
              <div class="yxj-center mobile-review" @touchmove.stop>
                <router-link tag="div" class="box" :class="activeTab === 'home' ? 'is-active' : ''"
                  to="/home">首页</router-link>
                <!-- <router-link
                  tag="div"
                  class="box"
                  :class="activeTab === 'product' ? 'is-active' : ''"
                  to="/product"
                >曝光台</router-link> -->

                <!-- <div class="box" :class="activeTab.indexOf('media') != -1 ? 'is-active' : ''" to="/media">理论学习
                  <div class="box-select">
                    <router-link tag="div" class="select-option"
                      :class="activeTab.indexOf('questionBank') != -1 ? 'is-active' : ''"
                      to="/media/questionBank">题库学习</router-link>
                    <router-link tag="div" class="select-option"
                      :class="activeTab.indexOf('videoLearning') != -1 ? 'is-active' : ''"
                      to="/media/videoLearning">视频学习</router-link>
                    <router-link tag="div" class="select-option3"
                      :class="activeTab.indexOf('graduation') != -1 ? 'is-active' : ''"
                      to="/media/graduation">结业鉴定</router-link>
                  </div>
                </div> -->


                <!-- <div
                  class="box"
                  :class="activeTab.indexOf('recruitment') != -1 ? 'is-active' : ''"
                  to="/recruitment"
                >学驾服务
                  <div class="box-select">
                    
                  </div>
                </div> -->

                <router-link tag="div" class="select-option box"
                  :class="activeTab.indexOf('driveschool') != -1 ? 'is-active' : ''"
                  to="/recruitment/driveschool">驾校风采</router-link>
                <router-link tag="div" class="select-option box"
                  :class="activeTab.indexOf('instructor') != -1 ? 'is-active' : ''"
                  to="/recruitment/instructor">教练风采</router-link>




                <router-link tag="div" class="box" :class="activeTab === 'about' ? 'is-active' : ''"
                  to="/about">行业动态</router-link>
                <!-- <router-link
                  tag="div"
                  class="box"
                  :class="activeTab === 'notice' ? 'is-active' : ''"
                  to="/notice"
                >公示栏</router-link> -->
                <router-link tag="div" class="box" :class="activeTab === 'join' ? 'is-active' : ''"
                  to="/culture">政策法规</router-link>









                <router-link tag="div" class="box" :class="activeTab === 'contact' ? 'is-active' : ''"
                  to="/contact">投诉建议</router-link>
              </div>
            </div>
            <!-- <div class="loginCon">
              <div class="line"></div>
              <div class="login" @click="tapJumpJaxc">登录</div>
            </div> -->

          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <transition name="fade-transform" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '@/components/Foot'
import { mapState } from 'vuex'
import getOsInfo from "@/utils/getOsInfo";
export default {
  name: 'Wrapper',
  data() {
    return {
      activeTab: '',
      oldScroll: 0,
      openMenu: false,
      clientWidth: null,
      os: null,
      hiddenHeader: false,
    }
  },
  components: {
    Foot
  },
  watch: {
    '$route'(to) {
      // 监听路由变化
      this.init(to.path)
      this.$store.commit('CHANGE_OPENMENU', false)
    },
  },
  created() {

    this.init(this.$route.path)
    this.getOs();
    if (this.clientWidth <= 1199) {
      this.$store.commit('CHANGE_MOBILE', true)
      this.onScroll()
    } else {
      this.$store.commit('CHANGE_MOBILE', false)
      this.$nextTick(() => {
        document.getElementById('header').style.transform = 'translateY(0px)'
        document.getElementById('mask').style.transform = 'translateY(0px)'
      })
    }
    window.onresize = () => {
      this.getOs();
      if (this.clientWidth <= 1199) {
        this.$store.commit('CHANGE_MOBILE', true)
        this.onScroll()
      } else {
        window.onscroll = ''
        this.$store.commit('CHANGE_MOBILE', false)
        this.$nextTick(() => {
          document.getElementById('header').style.transform = 'translateY(0px)'
          document.getElementById('mask').style.transform = 'translateY(0px)'
        })
      }
    }
  },
  computed: {
    ...mapState({
      mobile: state => state.user.mobile
    })
  },
  methods: {
    init(path) {
      if (path === '/home') {
        this.activeTab = 'home'
        return
      }
      if (path === '/product') {
        this.activeTab = 'product'
        return
      }
      if (path === '/media') {
        this.activeTab = 'download'
        return
      }
      if (path === '/about') {
        this.activeTab = 'about'
        return
      }
      if (path === '/notice') {
        this.activeTab = 'notice'
        return
      }
      if (path === '/culture') {
        this.activeTab = 'join'
        return
      }
      if (path === '/recruitment') {
        this.activeTab = 'recruitment'
        return
      }
      if (path === '/contact') {
        this.activeTab = 'contact'
        return
      }
      if (path === '/recruitment/driveschool') {
        this.activeTab = '/recruitment/driveschool'
        return
      }
      if (path === '/recruitment/instructor') {
        this.activeTab = '/recruitment/instructor'
        return
      }
      this.activeTab = path
    },
    onScroll() {
      window.onscroll = () => {
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 112) {
          document.getElementById('header').style.transform = 'translateY(-56px)'
          document.getElementById('mask').style.transform = 'translateY(56px)'
        }
        if (this.oldScroll > scrollTop) {
          document.getElementById('header').style.transform = 'translateY(0px)'
          document.getElementById('mask').style.transform = 'translateY(0px)'
        }
        this.oldScroll = scrollTop
      }
    },
    getOs() {
      this.os = new getOsInfo();
      this.clientWidth = this.os.version == 'Iphone' || this.os.version == 'Android' ? document.body.clientWidth : window.innerWidth
    },
    tapJumpJaxc() {
      // window.open("https://www.jaxc.cn/jiaxiao/login",'_blank')
      this.$router.push({
        name: 'login',

      })
    }
  },
  beforeDestroy() {
    window.onresize = function () { };
    window.onscroll = function () { };
  }
}
</script>

<style lang="scss" scoped>
@import "./Wrapper.scss";
</style>
