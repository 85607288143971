<template>
  <div class="foot">
    <div class="foot-connect">
      <div class="connect-we">
        <div class="caption">联系我们</div>
        <ul class="message">
          <li class="msg-li">地址：河南省洛阳市西工区310国道: 衡山路交叉口东南角立兴培训中3楼</li>
          <li class="msg-li">电话：0379-61115886</li>
          <li class="msg-li">E-Mail：dev@lydaoxie.com</li>
        </ul>
      </div>
      <div class="connect-we ml20">
        <div class="caption">洛阳学车app介绍</div>
        <ul class="message">
          <li class="msg-li">洛阳学车app 服务学员学车培训，主要功能有学员报名，缴费，预约科目培训，学时累计等功能</li>
        </ul>
      </div>
      <!-- <div class="connect-code">
        <div class="scan-code">
          <img
            class="code-img"
            src="../assets/zlxcimgs/home_icon_appxiazai@2x.png"
          >
          <p class="code-text">扫码关注公众号</p>
        </div>
        <div class="scan-code">
          <img
            class="code-img"
            src="../assets/zlxcimgs/category_icon01@2x.png"
          >
          <p class="code-text">扫码下载</p>
        </div>
      </div> -->
    </div>
    <div class="foot-area">
      <div class="copyright">
        <span class="first"><pre><br v-if="$store.getters.mobile"/>  版权所有：洛阳市道路运输协会  指导单位：洛阳市道路运输行业协会  技术支持：浙江迈威科技有限公司</pre></span><br/>
        <!-- <span class="a-first" target="_bank" href="javascript:void(0);">技术支持：浙江迈威科技有限公司</span><br />   -->
        <span class="row">
          <img src="../assets/images/beian.png" alt="">
          <!-- <a rel="noreferrer" target="_blank">浙公网安备33010602013637</a> -->
        </span>
        <a target="_bank" href="https://beian.miit.gov.cn">豫ICP备2024081113号-1</a>
      </div>
    </div>
  </div>
</template>
<!-- 洛阳市道路运输协会 -->
<script>
export default {
  name: 'Foot',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./foot.scss";
.foot {
  min-width: 1200px;
}
.row {
  display: inline-block;
  text-align: center;
  margin-right: 6px;
  img {
    width: 16px;
    height: 18px;
    margin: -2px 4px 0 6px;
    vertical-align:middle
  }
}
</style>
